const color = {
  props: {
    activ: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    color: {
      type: String,
      default: ""
    }
  },
  computed: {
    colorValue() {
      if (this.color && !this.activ) return this.color;
      return this.activ ? "#1472E6" : "#000000";
    }
  }
};

export default color;
