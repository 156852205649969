<!--  -->
<template>
  <svg
    t="1579057211339"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3680"
    :width="width + 'px'"
    :height="height + 'px'"
    :fill="colorValue"
  >
    <path
      d="M810.666667 128a42.666667 42.666667 0 0 1 42.666666 42.666667v682.666666a42.666667 42.666667 0 0 1-42.666666 42.666667H213.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666666-42.666667h597.333334z m0 42.666667H213.333333v682.666666h597.333334V170.666667z m-85.333334 512v42.666666h-170.666666v-42.666666h170.666666z m0-128v42.666666h-170.666666v-42.666666h170.666666z m-213.333333-298.666667v213.333333H298.666667V256h213.333333z m-42.666667 42.666667H341.333333v128h128V298.666667z"
      p-id="3681"
    ></path></svg
></template>

<script>
import color from "../../mixins/setColor";

export default {
  name: "download",
  mixins: [color]
};
</script>
