<template>
  <div
    :class="bem()"
    :style="{
      width: rightShow ? '40%' : '100%',
      padding: rightShow ? '20px 0' : '90px 0'
    }"
  >
    <div
      :class="bem('box')"
      :style="{
        paddingBottom:
          rightShow && isScrollBottom ? rightPaddingBottom + 'px' : '0px'
      }"
    >
      <LogoSlogan :class="bem('logo-wrap')" :width="224" :height="62" />

      <div :class="bem('describe-box')">
        <div :class="bem('describe-body')">
          <div :class="bem('describe')">
            <IconDownload :width="48" :height="48" />
            <div :class="bem('describe-wrap')">
              <p :class="bem('describe-title')">无需下载</p>
              <p :class="bem('describe-sub-title')">
                操作便捷，打开PowerPoint即可使用
              </p>
            </div>
          </div>
          <div :class="bem('describe')">
            <IconDesign :width="48" :height="48" />
            <div :class="bem('describe-wrap')">
              <p :class="bem('describe-title')">专业设计</p>
              <p :class="bem('describe-sub-title')">从场景出发，20种设计风格</p>
            </div>
          </div>
          <div :class="bem('describe')">
            <IconCopyright :width="48" :height="48" />
            <div :class="bem('describe-wrap')">
              <p :class="bem('describe-title')">版权无忧</p>
              <p :class="bem('describe-sub-title')">
                正版图片字体，原创模板，放心商用
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <Button type="primary" :class="bem('btn')">免费使用</Button> -->
      <a
        :class="bem('download-btn')"
        href="http://dancf-st-ppt.oss-cn-hangzhou.aliyuncs.com/package/vsto-1.0.0/updates/FocoSlide.exe"
        download="focoSlide"
        >免费使用</a
      >
      <div :class="bem('bottom')">
        <div>
          <span>如遇到问题请 </span>
          <Popover :class="bem('customer-btn')" placement="top">
            联系客服
            <span slot="content">
              <img
                :class="bem('customer-img')"
                src="../assets/customer-service.jpg"
                alt="客服微信二维码"
              />
              <p :class="bem('customer-text')">添加客服微信</p>
            </span>
          </Popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoSlogan from "../../../components/icons/logo-slogan.vue";
import IconDownload from "../../../components/icons/download.vue";
import IconDesign from "../../../components/icons/design.vue";
import IconCopyright from "../../../components/icons/copyright.vue";
import { Button, Popover } from "@gaoding/gdui";
import bemMixin from "../../../mixins/bem";

export default {
  name: "about-page-right",
  mixins: [bemMixin],
  components: {
    LogoSlogan,
    IconDownload,
    IconDesign,
    IconCopyright,
    // Button,
    Popover
  },
  props: {
    rightShow: {
      type: Boolean,
      default: true
    },
    isScrollBottom: {
      type: Boolean,
      default: false
    },
    rightPaddingBottom: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style lang="less" scoped>
@import "../../../mixins/base.less";

@page-right: ~"about-page-right";

.@{page-right} {
  width: 40%;
  height: 100%;
  background: url(../assets/about-bg-1.png) no-repeat no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  &__box {
    width: 100%;
  }

  &__logo-wrap {
  }

  &__describe-box {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__describe-body {
  }

  &__describe {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 7%;

    &-wrap {
      text-align: left;
      margin-left: 1rem;

      p {
        margin: 0;
      }
    }

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.75rem;
      color: rgba(0, 0, 0, 1);
    }

    &-sub-title {
      font-size: 1rem;
      font-weight: 400;
      color: rgba(141, 148, 158, 1);
      line-height: 1.5rem;
    }
  }

  &__btn {
    font-size: 1rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    width: 15rem;
    height: 3.25rem;
    margin-top: 5rem;
  }

  &__download-btn {
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    background: #2254f4;
    width: 15rem;
    height: 3.25rem;
    line-height: 3.25rem;
    margin-top: 5rem;
    border-radius: 4px;
    text-decoration: none;
  }

  &__bottom {
    font-size: 0.875rem;
    font-weight: 400;
    width: 10rem;
    color: rgba(0, 0, 0, 1);
    line-height: 1.5rem;
    margin: 2.25rem auto 0;
  }
  &__customer-btn {
    color: rgba(50, 115, 238, 1);
    cursor: pointer;
  }
  &__customer-img {
    box-sizing: border-box;
    width: 6.5rem;
    height: 6.5rem;
    margin: 0.75rem 0.75rem 0 0.75rem;
  }
  &__customer-text {
    font-size: 0.75rem;
    color: rgba(68, 73, 80, 1);
    margin-bottom: 0.75rem;
  }
}
</style>
