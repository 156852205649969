<!--  -->
<template>
  <svg
    t="1579057085145"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3442"
    :width="width + 'px'"
    :height="height + 'px'"
    :fill="colorValue"
  >
    <path
      d="M810.666667 128a42.666667 42.666667 0 0 1 42.666666 42.666667v682.666666a42.666667 42.666667 0 0 1-42.666666 42.666667H213.333333a42.666667 42.666667 0 0 1-42.666666-42.666667v-128a85.333333 85.333333 0 1 1 0-170.666666v-85.333334a85.333333 85.333333 0 1 1 0-170.666666V170.666667a42.666667 42.666667 0 0 1 42.666666-42.666667h597.333334z m0 42.666667H213.333333v128h85.333334v64H234.666667V341.333333H170.666667a42.666667 42.666667 0 1 0 0 85.333334V384h42.666666v170.666667h85.333334v64H234.666667V597.333333H170.666667a42.666667 42.666667 0 0 0 0 85.333334v-42.666667h42.666666v213.333333h597.333334V170.666667z m-170.666667 128v213.333333H384V298.666667h256z m-42.666667 42.666666h-170.666666v128h170.666666V341.333333z"
      p-id="3443"
    ></path></svg
></template>

<script>
import color from "../../mixins/setColor";

export default {
  name: "download",
  mixins: [color]
};
</script>
