<!--  -->
<template>
  <svg
    t="1579056947693"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3204"
    :width="width + 'px'"
    :height="height + 'px'"
    :fill="colorValue"
  >
    <path
      d="M640 128a42.666667 42.666667 0 0 1 42.666667 42.666667v128h128a42.666667 42.666667 0 0 1 42.666666 42.666666v554.666667a42.666667 42.666667 0 0 1-42.666666 42.666667H384a42.666667 42.666667 0 0 1-42.666667-42.666667v-128H213.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666666-42.666667h426.666667z m170.666667 213.333333h-128v384a42.666667 42.666667 0 0 1-42.666667 42.666667H384v128h426.666667V341.333333z m-170.666667-170.666666H213.333333v554.666666h426.666667V170.666667zM384 256v170.666667h-42.453333V300.16l-29.098667 20.949333-21.290667-26.88L341.504 256H384z"
      p-id="3205"
    ></path>
  </svg>
</template>

<script>
import color from "../../mixins/setColor";

export default {
  name: "download",
  mixins: [color]
};
</script>
