<!--  -->
<template>
    <svg
      t="1576417977257"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2786"
      :width="width+'px'"
      :height="height+'px'"><path d="M281.237333 198.08L190.72 288.597333l75.434667 75.413334 45.248-45.226667 15.082666 15.061333-45.248 45.269334 45.269334 45.226666 45.226666-45.226666 15.104 15.082666-45.226666 45.226667 60.309333 60.330667 90.517333-90.496-211.2-211.178667z m455.893334 177.685333L646.613333 285.226667 284.394667 647.466667c-14.869333 14.848-28.053333 36.928-32.085334 57.6L233.557333 789.333333l83.989334-19.114666c20.48-4.096 42.389333-17.237333 57.130666-32l362.453334-362.453334z m90.517333-120.682666l-60.352-60.330667a21.269333 21.269333 0 0 0-30.08-0.085333l-75.52 75.498666 90.517333 90.496 75.52-75.498666a21.269333 21.269333 0 0 0-0.085333-30.08zM383.936 759.104a128 128 0 0 1-65.088 34.944l-97.045333 19.648a10.666667 10.666667 0 0 1-12.586667-12.522667l19.221333-97.28a128 128 0 0 1 35.072-65.706666l123.306667-123.328-210.346667-210.346667c-8.789333-8.789333-9.173333-22.677333-0.832-30.997333l90.517334-90.517334c8.32-8.32 22.208-7.957333 30.997333 0.853334l210.346667 210.346666 211.2-211.2c16.661333-16.64 39.573333-26.624 63.274666-2.922666l60.330667 60.330666c25.045333 25.045333 13.738667 46.613333-2.922667 63.274667l-211.2 211.2 210.346667 210.346667c8.810667 8.789333 9.173333 22.677333 0.853333 30.997333l-90.517333 90.517333c-8.32 8.32-22.208 7.957333-30.997333-0.853333l-210.346667-210.346667 15.082667-15.082666 60.352 60.330666 45.226666-45.226666 15.082667 15.061333-45.226667 45.248 45.226667 45.269333 45.269333-45.248 15.082667 15.082667-45.248 45.248 75.413333 75.434667 90.517334-90.517334-211.178667-211.2-229.184 229.162667z" p-id="2787"></path></svg>
</template>

<script>
import color from '../../mixins/setColor'

export default {
  name: 'design',
  mixins: [color],
}
</script>