<template>
  <div class="focoslide_landingpage" id="landingpage">
    <div class="focoslide_landingpage-share-icon">
      <img src="./assets/focoslide-logo-icon.png" />
    </div>
    <a
      class="focoslide_landingpage-help"
      href="https://www.focoslide.com/help"
      target="_blank"
    >
      <div class="focoslide_landingpage-help_icon" />
      <div class="focoslide_landingpage-help_text">帮助中心</div>
    </a>
    <Header></Header>
    <div class="landingpage_model-wrap1">
      <div class="landingpage_model-videobox1">
        <img
          class="title landingpage_model-img1"
          src="./assets/focoslide-logo.png"
          width="246"
          height="56"
          alt="FocoSlide"
        />
        <div class="subtitle landingpage_model-subtitle1">
          PowerPoint 辅助设计工具，10000+专业模板素材随时取用，轻松制作高品质
          PPT
        </div>
        <div class="landingpage_model-tips1">
          仅适用于 Microsoft Office 2013/2016/2019/365
        </div>
        <a class="button landingpage_model-button1" v-bind:href="download_url">
          立即下载
        </a>
        <video
          muted
          loop="loop"
          id="video1"
          class="landingpage_model-video1"
          src="./assets/video1.mp4"
          poster="./assets/video-poster1.png"
        ></video>
      </div>
    </div>
    <div class="landingpage_model-wrap landingpage_model-wrap_white">
      <div class="landingpage_model-textbox_left">
        <div class="title landingpage_model-title">创作神器提升效率</div>
        <div class="subtitle landingpage_model-subtitle">
          全场景化内容架构，节约70%创作时间
        </div>
        <a class="button landingpage_model-button" v-bind:href="download_url">
          立即下载
        </a>
      </div>
      <div class="landingpage_model-videobox">
        <video
          muted
          loop="loop"
          id="video2"
          class="landingpage_model-video"
          src="./assets/video2.mp4"
          poster="./assets/video-poster2.png"
        ></video>
      </div>
    </div>
    <div class="landingpage_model-wrap landingpage_model-wrap_grey">
      <div class="landingpage_model-videobox">
        <video
          muted
          loop="loop"
          id="video3"
          class="landingpage_model-video"
          src="./assets/video3.mp4"
          poster="./assets/video-poster3.png"
        ></video>
      </div>
      <div class="landingpage_model-textbox_right">
        <div class="title landingpage_model-title">灵活设计随心搭配</div>
        <div class="subtitle landingpage_model-subtitle">
          套装/单页模板，海量设计任意组合
        </div>
        <a class="button landingpage_model-button" v-bind:href="download_url">
          立即下载
        </a>
      </div>
    </div>
    <div class="landingpage_model-wrap landingpage_model-wrap_white">
      <div class="landingpage_model-textbox_left2">
        <div class="title landingpage_model-title">个性化素材提升页面质感</div>
        <div class="subtitle landingpage_model-subtitle">
          多种图片/图标/插画/形状，新手也能做出生动美观的 PPT
        </div>
        <a class="button landingpage_model-button" v-bind:href="download_url">
          立即下载
        </a>
      </div>
      <div class="landingpage_model-videobox2">
        <video
          muted
          loop="loop"
          id="video4"
          class="landingpage_model-video2"
          src="./assets/video4.mp4"
          poster="./assets/video-poster4.png"
        ></video>
      </div>
    </div>
    <div class="focoslide_landingpage-introduction">
      如今，PPT应用的场景非常之多，为了让PPT制作得更加简单方便，同时也更加简单美观生动，在制作PPT的时候可以借助一些插件工具。为此，稿定设计专门推出了FocoSlide这款PPT插件，这款FocoSlide插件工具有着全场景化的内容架构，能够节约70%创作时间，是提升工作效率的创作神器。另外，FocoSlide插件有丰富精美的套装和单页模板供选择，让你任意组合，灵活设计，轻松搞定专属于自己的PPT。还有多种图片、图标、插画、形状等个性化元素的加持，轻松提高PPT页面的质感。有了稿定PPT插件，即使是新手也能做出生动美观的PPT。你也想要来体验下吗？那就快来下载FocoSlide插件吧！
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header";
import Footer from "../../components/footer";

export default {
  name: "landingpage",
  props: {},
  components: {
    Header,
    Footer
  },
  data() {
    return {
      download_url: "https://ppt.gaoding.com/plugin",
      isOnPlay: false,
      playIndex: null
    };
  },
  computed: {},
  // beforeCreate() {
  //   location.replace("https://www.gaoding.com/ppt/plugin");
  // },
  mounted() {
    if (this.IsPC()) {
      this.playVideo();
      window.addEventListener(
        "scroll",
        this.throttle(this.onScroll, 30),
        false
      );
    } else {
      this.initOnWap();
    }
  },
  methods: {
    initOnWap() {
      const $textboxLeftList = document.getElementsByClassName(
        "landingpage_model-textbox_left"
      );
      $textboxLeftList[0].style.width = "435px";
      const $textboxRightList = document.getElementsByClassName(
        "landingpage_model-textbox_right"
      );
      $textboxRightList[0].style.width = "435px";
      const $textboxLeftList2 = document.getElementsByClassName(
        "landingpage_model-textbox_left2"
      );
      $textboxLeftList2[0].style.width = "435px";
      for (let m = 0; m < $textboxRightList.length; m++) {
        $textboxRightList[m].style.width = "475px";
      }
      const $titleList = document.getElementsByClassName("title");
      const $subtitleList = document.getElementsByClassName("subtitle");
      const $buttonList = document.getElementsByClassName("button");
      for (let i = 0; i < 4; i++) {
        const $video = document.querySelector(`#video${i + 1}`);
        $video.controls = "controls";
        if ($titleList[i].className.indexOf("onshow") === -1 && i !== 0) {
          $titleList[i].className = "title landingpage_model-title onshow1";
          $subtitleList[i].className =
            "subtitle landingpage_model-subtitle onshow2";
          $buttonList[i].className = "button landingpage_model-button onshow3";
        }
      }
    },
    IsPC() {
      const userAgentInfo = window.navigator.userAgent;
      const Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      ];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    onScroll() {
      this.playVideo();
    },
    playVideo() {
      const scrollTop = this.getScrollTop();
      for (let i = 0; i < 4; i++) {
        const $video = document.querySelector(`#video${i + 1}`);
        if (
          0 < $video.offsetTop - scrollTop &&
          $video.offsetTop - scrollTop < window.screen.height / 1.5
        ) {
          $video.play();
          const $titleList = document.getElementsByClassName("title");
          const $subtitleList = document.getElementsByClassName("subtitle");
          const $buttonList = document.getElementsByClassName("button");
          if ($titleList[i].className.indexOf("onshow") === -1 && i !== 0) {
            $titleList[i].className = "title landingpage_model-title onshow1";
            $subtitleList[i].className =
              "subtitle landingpage_model-subtitle onshow2";
            $buttonList[i].className =
              "button landingpage_model-button onshow3";
          }
        } else {
          $video.pause();
        }
      }
    },
    getScrollTop() {
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
    throttle(func, delay) {
      let prev = Date.now();
      return function() {
        const context = this;
        const args = arguments;
        const now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    }
  }
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接，鼠标在元素上按下还没有松开*/
a:active {
  text-decoration: none;
}
/* 获得焦点的时候 鼠标松开时显示的颜色*/
a:focus {
  text-decoration: none;
}
.focoslide_landingpage {
  &-share-icon {
    width: 0;
    height: 0;
    overflow: hidden;
  }

  &-help {
    position: absolute;
    top: 17px;
    right: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;

    &_icon {
      width: 20px;
      height: 20px;
      background: url("./assets/help_outline.svg") no-repeat;
      background-size: 100% 100%;
    }
    &_text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9291a8;
      margin-left: 4px;
      height: 22px;
      line-height: 20px;
    }
  }
  &-help:hover {
    .focoslide_landingpage-help_icon {
      width: 20px;
      height: 20px;
      background: url("./assets/help_outline-hover.svg") no-repeat;
      background-size: 100% 100%;
    }
    .focoslide_landingpage-help_text {
      color: #4372e6;
    }
  }

  &-introduction {
    width: 1160px;
    padding: 0;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(157, 163, 172, 1);
    line-height: 24px;
    border-top: 1px solid #f1f3f4;
    padding-top: 40px;
    margin: 0 auto;
    margin-bottom: 48px;
    text-align: left;
  }
}
.landingpage_model {
  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 708px;
    background: rgba(255, 255, 255, 1);

    &_white {
      background: rgba(255, 255, 255, 1);
    }

    &_grey {
      background: rgba(247, 249, 254, 1);
    }
  }

  &-wrap1 {
    width: 100%;
    background: url(./assets/bg1.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 74px;
    padding-bottom: 178px;
  }

  &-videobox1 {
    text-align: center;
  }

  &-img1 {
    display: block;
    margin: 0 auto;
    transform: translateX(-6px);
  }

  &-title1 {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(40, 39, 94, 1);
    text-align: center;
  }

  &-subtitle1 {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(81, 80, 117, 1);
    margin-top: 23px;
    text-align: center;
  }

  &-tips1 {
    font-size: 16px;
    height: 18px;
    line-height: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9190a8;
    margin-top: 12px;
  }

  &-video1 {
    width: 954px;
    height: 535px;
    margin-top: 60px;
    box-shadow: 10px 30px 51px 0px rgba(134, 146, 161, 0.15);
  }

  &-button1 {
    width: 216px;
    height: 48px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    background: rgba(67, 114, 230, 1);
    color: rgba(255, 255, 255, 1);
    outline: none;
    cursor: pointer;
    display: block;
    text-align: center;
    line-height: 48px;
    margin: 0 auto;
    margin-top: 32px;
    transition: all 0.1s linear;
  }

  &-button1:hover {
    transition: all 0.1s linear;
    background: #2e4fa0;
    color: rgba(255, 255, 255, 1);
  }

  &-textbox_left {
    width: 535px;
    text-align: left;
    transform: translateY(-30px);
  }

  &-textbox_left2 {
    width: 499px;
    text-align: left;
    transform: translateY(-30px);
  }

  &-textbox_right {
    width: 535px;
    text-align: right;
    transform: translateY(-30px);
  }

  &-videobox {
    width: 682px;
    height: 437px;
  }

  &-video {
    width: 682px;
    height: 437px;
  }

  &-videobox2 {
    width: 661px;
    height: 462px;
  }

  &-video2 {
    width: 661px;
    height: 462px;
  }

  &-title {
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(40, 39, 94, 1);
    line-height: 50px;
    opacity: 0;
    transform: translateY(60px);
  }

  &-subtitle {
    width: 420px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(81, 80, 117, 1);
    line-height: 38px;
    margin-top: 16px;
    display: inline-block;
    opacity: 0;
    transform: translateY(60px);
  }

  &-button {
    width: 120px;
    height: 48px;
    border: 1px solid rgba(168, 168, 202, 1);
    margin-top: 38px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(40, 39, 94, 1);
    background: transparent;
    outline: none;
    cursor: pointer;
    opacity: 0;
    transform: translateY(60px);

    display: inline-block;
    text-align: center;
    line-height: 48px;
    transition: all 0.1s linear;
  }

  &-button:hover {
    border: 1px solid rgba(67, 114, 230, 1);
    color: #ffffff;
    background: rgba(67, 114, 230, 1);
    transition: all 0.1s linear;
  }
}
.onshow1 {
  animation: fadeInUp 0.6s 0.2s ease both;
}
.onshow2 {
  animation: fadeInUp 0.6s 0.4s ease both;
}
.onshow3 {
  animation: fadeInUp 0.6s 0.6s ease both;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
