<template>
  <div
    class="header"
    :style="{
      background: background
    }"
  >
    <div class="header-warp">
      <router-link v-if="logoColor === 'black'" to="/">
        <Logo :width="124" :height="28" />
      </router-link>
      <router-link v-if="logoColor === 'white'" to="/">
        <LogoWhite :width="124" :height="28" />
      </router-link>
      <Button v-if="false">登录</Button>
    </div>
  </div>
</template>

<script>
import { Button } from "@gaoding/gdui";
import Logo from "../icons/logo.vue";
import LogoWhite from "../icons/logo-white.vue";

export default {
  name: "header",
  components: {
    Button,
    Logo,
    LogoWhite
  },
  props: {
    background: {
      type: String,
      default: ""
    },
    logoColor: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less">
.header {
  position: absolute;
  z-index: 999;
  width: 100%;
}
.header-warp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 32px;
}
</style>
