<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "home"
};
</script>

<style lang="less">
html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 0;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@media (max-width: 2560px) {
  html {
    font-size: 28px;
  }
}

@media (max-width: 2160px) {
  html {
    font-size: 24px;
  }
}

@media (max-width: 1800px) {
  html {
    font-size: 20px;
  }
}

@media (max-width: 1620px) {
  html {
    font-size: 18px;
  }
}

@media (max-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1350px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1260px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1170px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1080px) {
  html {
    font-size: 12px;
  }
}
</style>
