<!--  -->
<template>
  <svg
    t="1579057286462"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3799"
    :width="width + 'px'"
    :height="height + 'px'"
    :fill="colorValue"
  >
    <path
      d="M810.666667 128a42.666667 42.666667 0 0 1 42.666666 42.666667v682.666666a42.666667 42.666667 0 0 1-42.666666 42.666667H213.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V170.666667a42.666667 42.666667 0 0 1 42.666666-42.666667h597.333334z m0 42.666667H213.333333v682.666666h597.333334V170.666667zM362.666667 597.333333a32 32 0 1 1 0 64 32 32 0 0 1 0-64z m320 10.666667v42.666667h-213.333334v-42.666667h213.333334zM362.666667 469.333333a32 32 0 1 1 0 64 32 32 0 0 1 0-64z m320 10.666667v42.666667h-213.333334v-42.666667h213.333334zM362.666667 341.333333a32 32 0 1 1 0 64 32 32 0 0 1 0-64z m320 10.666667v42.666667h-213.333334v-42.666667h213.333334z"
      p-id="3800"
    ></path></svg
></template>

<script>
import color from "../../mixins/setColor";

export default {
  name: "download",
  mixins: [color]
};
</script>
