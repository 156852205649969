import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/web/pages/about/index.vue";
import Page404 from "../views/web/pages/404/index.vue";
import Help from "../views/web/pages/help2/index.vue";
// 文档说明页
import Documentation from "../views/web/pages/documentation/index.vue";
import Landingpage from "../views/web/pages/landingpage/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landingpage",
    component: Landingpage
  },
  {
    path: "/web/about",
    name: "about",
    component: About
  },
  {
    path: "/protocol/userProtocol",
    name: "userProtocol",
    meta: {
      title: "userProtocol"
    },
    component: () =>
      import(
        /* webpackChunkName: "userProtocol" */ "../views/web/pages/protocol/user-protocol.vue"
      )
  },
  {
    path: "/protocol/privacyProtocol",
    name: "privacyProtocol",
    meta: {
      title: "privacyProtocol"
    },
    component: () =>
      import(
        /* webpackChunkName: "privacyProtocol" */ "../views/web/pages/protocol/privacy-protocol.vue"
      )
  },
  {
    path: "/protocol/memberProtocol",
    name: "memberProtocol",
    meta: {
      title: "memberProtocol"
    },
    component: () =>
      import(
        /* webpackChunkName: "memberProtocol" */ "../views/web/pages/protocol/member-protocol.vue"
      )
  },
  // 文档说明页
  {
    path: "/documentation",
    name: "documentation",
    redirect: "/help"
    // component: Documentation
  },
  {
    path: "/help",
    name: "help",
    component: Help
  },
  // 下载页
  {
    path: "/landingpage",
    name: "landingpage",
    component: Landingpage
  },
  {
    path: "/404",
    name: "404",
    component: Page404
  },
  {
    path: "*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
