<template>
  <div>
    <img src="../../assets/404.png" alt="404" width="80%" />
  </div>
</template>

<script>
export default {
  name: "404",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped></style>
