<!--  -->
<template>
    <svg
      t="1576418139216"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2898"
      :width="width+'px'"
      :height="height+'px'"><path d="M512 149.333333c133.845333 30.805333 231.381333 64.789333 320 93.269334l-0.128 248.682666c0 31.402667-3.562667 60.821333-10.090667 88.32a160 160 0 1 1-199.488 247.637334l1.408 1.386666c-37.333333 21.013333-75.776 36.266667-111.701333 46.037334-140.608-38.186667-319.872-160.704-319.872-383.402667L192 242.602667l81.834667-26.538667C341.610667 194.261333 417.813333 171.008 512 149.333333z m224 426.666667a138.666667 138.666667 0 1 0 0 277.333333 138.666667 138.666667 0 0 0 0-277.333333zM512 171.221333c-43.626667 10.133333-85.418667 21.077333-127.573333 33.216-23.594667 6.805333-46.762667 13.802667-72 21.738667l-25.984 8.234667L213.333333 258.090667l0.128 233.173333c0 180.224 129.216 313.173333 298.538667 361.258667a478.890667 478.890667 0 0 0 97.706667-39.616l0.277333 0.362666a160 160 0 0 1 192.725333-244.053333l-0.554666-0.277333c4.928-22.229333 7.765333-45.44 8.298666-69.504l0.085334-8.149334 0.128-233.173333-86.314667-27.904c-30.293333-9.6-57.258667-17.834667-84.757333-25.770667A2500.117333 2500.117333 0 0 0 512 171.221333z m106.325333 651.861334l0.149334 0.149333-0.149334-0.149333z m-8.618666-10.176l0.277333 0.362666 2.026667 2.517334 1.642666 2.005333-3.946666-4.885333zM736 640c26.666667 0 50.048 13.973333 63.253333 34.986667l-20.053333 8.362666a53.333333 53.333333 0 1 0 0 62.634667l20.053333 8.384A74.666667 74.666667 0 1 1 736 640z m-100.202667-244.8l15.082667 15.082667-150.848 150.826666v0.021334l-15.082667 15.082666-90.496-90.496 15.082667-15.082666 75.413333 75.392 150.848-150.826667z" p-id="2899"></path></svg>
</template>

<script>
import color from '../../mixins/setColor'

export default {
  name: 'copyright',
  mixins: [color],
}
</script>