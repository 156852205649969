<!--  -->
<template>
    <svg
      t="1576417555473"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2674"
      :width="width+'px'"
      :height="height+'px'"><path d="M597.098667 191.168a21.333333 21.333333 0 0 1 0.234666 3.2V234.666667h234.666667a21.333333 21.333333 0 0 1 21.184 18.837333L853.333333 256v512a21.333333 21.333333 0 0 1-18.837333 21.184L832 789.333333H597.333333v43.349334a21.333333 21.333333 0 0 1-24.661333 21.056l-384-60.672A21.333333 21.333333 0 0 1 170.666667 771.989333V252.821333a21.333333 21.333333 0 0 1 18.133333-21.098666l384-58.432a21.333333 21.333333 0 0 1 24.298667 17.877333zM576 194.858667L192 252.330667v518.336L576 832V194.858667zM597.333333 512h170.666667v21.333333h-170.666667v85.333334h170.666667v21.333333h-170.666667v128h234.666667V256H597.333333v256z m-149.333333-49.621333c0 41.109333-28.650667 73.642667-64 72.682666l-42.666667-1.152v106.56l-21.333333-2.901333V405.333333l21.333333-2.901333 42.666667-5.781333c35.349333-4.8 64 24.618667 64 65.728z m-64-42.666667l-42.666667 4.629333V512h42.666667c23.573333 0 42.666667-21.696 42.666667-48.469333 0-26.752-19.093333-46.378667-42.666667-43.818667z" p-id="2675"></path></svg>
</template>

<script>
import color from '../../mixins/setColor'

export default {
  name: 'download',
  mixins: [color],
}
</script>