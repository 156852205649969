<template>
  <div class="footer" id="footer">
    <div class="footer-warp">
      <div>
        <!-- 头部面包屑 -->
        <div class="g-breadcrumb-warp">
          <Breadcrumb separator="">
            <BreadcrumbItem>
              <a href="/protocol/userProtocol" target="_blank">用户协议</a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/protocol/privacyProtocol" target="_blank">隐私协议</a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/protocol/memberProtocol" target="_blank">会员协议</a>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <!-- 备注 -->
        <div :class="bem('copyright')">
          <p>地 址：广东省深圳市南山区科技园微软科通大厦15楼</p>
          <p>
            <a href="https://beian.miit.gov.cn/" rel="nofollow" target="_blank"
              >闽ICP备18003982号-8</a
            >
            | © 2017 - 2021 稿定（厦门）科技有限公司 &nbsp;|&nbsp;
            <span class="security">
              <img
                class="record"
                src="https://st-gdx.dancf.com/gaodingx/1681/configs/system/20200921-171149-d002.svg"
                alt="record icon"
              />
              互联网安全备案号:35020602001758
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Breadcrumb } from "@gaoding/gdui";
import bemMixin from "../../mixins/bem";
const BreadcrumbItem = Breadcrumb.item;

export default {
  name: "footer",
  mixins: [bemMixin],
  components: {
    Breadcrumb,
    BreadcrumbItem
  },
  data() {
    return {};
  }
};
</script>

<style lang="less">
a {
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接，鼠标在元素上按下还没有松开*/
a:active {
  text-decoration: none;
}
/* 获得焦点的时候 鼠标松开时显示的颜色*/
a:focus {
  text-decoration: none;
}
.footer {
  position: relative;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  background: rgba(18, 18, 19, 1);

  &-warp {
    width: 1160px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 38px 0 52px 0;
    margin: 0 auto;
  }

  .g-breadcrumb-warp {
    height: 26px;
  }
  .g-breadcrumb-item {
    align-items: flex-end;
  }
  .g-breadcrumb-item__inner,
  .g-breadcrumb-item a {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
  .g-breadcrumb-item__inner,
  .g-breadcrumb-item a:hover {
    color: rgba(67, 114, 230, 1);
  }

  .g-breadcrumb-item__separator {
    margin: 0 20px;
  }

  &__copyright {
    text-align: left;
    margin-top: 32px;

    > p {
      font-size: 14px;
      font-weight: 400;
      font-family: PingFangSC-Regular, PingFang SC;
      color: rgba(178, 180, 190, 1);
      line-height: 18px;
      margin: 0;
      margin-bottom: 12px;
    }

    a {
      color: inherit;
    }
  }

  &__qrcode {
    width: 96px;
    height: 96px;
  }
  &__qrcode-sec {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 18px;
    margin-top: 12px;
  }

  &__customer-btn {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    height: 26px;
    display: table-cell;
    vertical-align: bottom;
  }
  &__customer-btn:hover {
    color: rgba(67, 114, 230, 1);
  }
  &__customer-img {
    box-sizing: border-box;
    width: 96px;
    height: 96px;
    margin: 12px;
  }
  &__customer-text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: normal;
    color: rgba(0, 0, 0, 1);
    margin: 12px auto;
    margin-top: 0;
  }
}
</style>
