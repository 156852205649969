<template>
  <div
    :class="bem()"
    :style="{
      width: rightShow ? '60%' : '100%'
    }"
  >
    <div style="width: 100%">
      <img src="../assets/about-img-1.png" alt="1" width="100%" />
    </div>

    <div :class="bem('model')">
      <div :class="bem('model-text')">
        <p :class="bem('model-title')">高效找到适合你的PPT模板</p>
        <p :class="bem('model-title')">
          <span :class="bem('highlight-blue')">节约70%</span>
          的创作时间
        </p>
        <p :class="bem('model-sub-title')">
          从使用场景出发，精准筛选，选模板从此只需1分钟
        </p>
      </div>
      <img
        :class="bem('model-img')"
        src="../assets/about-img-2.png"
        alt="2"
        width="100%"
      />
    </div>

    <div :class="bem('model')">
      <div :class="bem('model-text')">
        <p :class="bem('model-title')">
          模版虽美，用起来却反被
          <span :class="bem('highlight-blue')">框住思路</span>
        </p>
        <p :class="bem('model-sub-title')">
          单页模板灵活搭配，简单排版自成一套，搭出自己的style
        </p>
      </div>
      <div :class="bem('category')">
        <div>
          <!-- 封面模板 -->
          <div :class="bem('TabPanel')" v-show="tabsName === 'cover'">
            <img src="../assets/cover-1.png" alt="" :class="bem('TabImg')" />
            <img src="../assets/cover-2.png" alt="" />
          </div>
          <!-- 目录模板 -->
          <div :class="bem('TabPanel')" v-show="tabsName === 'directory'">
            <img
              src="../assets/directory-1.png"
              alt=""
              :class="bem('TabImg')"
            />
            <img src="../assets/directory-2.png" alt="" />
          </div>
          <!-- 过渡模板 -->
          <div :class="bem('TabPanel')" v-show="tabsName === 'excessive'">
            <img
              src="../assets/excessive-1.png"
              alt=""
              :class="bem('TabImg')"
            />
            <img src="../assets/excessive-2.png" alt="" />
          </div>
          <!-- 图文模板 -->
          <div :class="bem('TabPanel')" v-show="tabsName === 'graphic'">
            <img src="../assets/graphic-1.png" alt="" :class="bem('TabImg')" />
            <img src="../assets/graphic-2.png" alt="" />
          </div>
          <!-- 封底模板 -->
          <div :class="bem('TabPanel')" v-show="tabsName === 'backCover'">
            <img
              src="../assets/back-cover-1.png"
              alt=""
              :class="bem('TabImg')"
            />
            <img src="../assets/back-cover-2.png" alt="" />
          </div>
        </div>
        <div :class="bem('tabs')">
          <div class="about-page-left__tabs-title">
            <div
              :class="
                tabsName === 'cover'
                  ? 'about-page-left__tabs-title-action'
                  : 'about-page-left__tabs-title-disaction'
              "
              @click="changetabs('cover')"
            >
              <Cover :width="32" :height="32" :activ="tabsName === 'cover'" />
              <p>封面模板</p>
            </div>
          </div>
          <div class="about-page-left__tabs-title">
            <div
              :class="
                tabsName === 'directory'
                  ? 'about-page-left__tabs-title-action'
                  : 'about-page-left__tabs-title-disaction'
              "
              @click="changetabs('directory')"
            >
              <Directory
                :width="32"
                :height="32"
                :activ="tabsName === 'directory'"
              />
              <p>目录模板</p>
            </div>
          </div>
          <div class="about-page-left__tabs-title">
            <div
              :class="
                tabsName === 'excessive'
                  ? 'about-page-left__tabs-title-action'
                  : 'about-page-left__tabs-title-disaction'
              "
              @click="changetabs('excessive')"
            >
              <Excessive
                :width="32"
                :height="32"
                :activ="tabsName === 'excessive'"
              />
              <p>过渡模板</p>
            </div>
          </div>
          <div class="about-page-left__tabs-title">
            <div
              :class="
                tabsName === 'graphic'
                  ? 'about-page-left__tabs-title-action'
                  : 'about-page-left__tabs-title-disaction'
              "
              @click="changetabs('graphic')"
            >
              <Graphic
                :width="32"
                :height="32"
                :activ="tabsName === 'graphic'"
              />
              <p>图文模板</p>
            </div>
          </div>
          <div class="about-page-left__tabs-title">
            <div
              :class="
                tabsName === 'backCover'
                  ? 'about-page-left__tabs-title-action'
                  : 'about-page-left__tabs-title-disaction'
              "
              @click="changetabs('backCover')"
            >
              <BackCover
                :width="32"
                :height="32"
                :activ="tabsName === 'backCover'"
              />
              <p>封底模板</p>
            </div>
          </div>
        </div>
      </div>
      <p :class="bem('note')">更多模板请登录FocoSlide体验</p>
    </div>

    <div :class="bem('model')">
      <div :class="bem('model-text')">
        <p :class="bem('model-title')">
          个性素材让PPT
          <span :class="bem('highlight-blue')">鲜活起来</span>
        </p>
        <p :class="bem('model-sub-title')">
          内含手绘插画、创意素材、通用图标、摄影图片等，页面
        </p>
        <p :class="bem('model-sub-title')">
          丰富程度由你来定
        </p>
      </div>

      <div :class="bem('material-model-warp')">
        <div :class="bem('material-model')">
          <div :class="bem('material-model__title-warp')">
            <span :class="bem('material-model__title')">手绘插画</span>
            <span :class="bem('material-model__sub-title')">
              / LLUSTRATIONS</span
            >
          </div>
          <ul :class="bem('material-model__img-warp')">
            <li>
              <img src="../assets/about-img-shch-1.png" alt="1" />
            </li>
            <li>
              <img src="../assets/about-img-shch-2.png" alt="1" />
            </li>
            <li>
              <img src="../assets/about-img-shch-3.png" alt="1" />
            </li>
            <li>
              <img src="../assets/about-img-shch-4.png" alt="1" />
            </li>
          </ul>
        </div>

        <div :class="bem('material-model')">
          <div :class="bem('material-model__title-warp')">
            <span :class="bem('material-model__title')">创意素材</span>
            <span :class="bem('material-model__sub-title')"> / MATERIAL</span>
          </div>
          <ul :class="bem('material-model__img-warp')">
            <li>
              <img src="../assets/about-img-cysc-1.png" alt="1" />
            </li>
            <li>
              <img src="../assets/about-img-cysc-2.png" alt="1" />
            </li>
            <li>
              <img src="../assets/about-img-cysc-3.png" alt="1" />
            </li>
            <li>
              <img src="../assets/about-img-cysc-4.png" alt="1" />
            </li>
          </ul>
        </div>

        <div :class="bem('material-model')">
          <div :class="bem('material-model__title-warp')">
            <span :class="bem('material-model__title')">通用图标</span>
            <span :class="bem('material-model__sub-title')"> / ICON</span>
          </div>
          <img
            :class="bem('material-model__img')"
            src="../assets/about-img-tytb.png"
            alt="1"
          />
        </div>

        <div :class="bem('material-model')">
          <div :class="bem('material-model__title-warp')">
            <span :class="bem('material-model__title')">图片素材</span>
            <span :class="bem('material-model__sub-title')"> / PHOTO</span>
          </div>
          <img
            :class="bem('material-model__img')"
            src="../assets/about-img-tpsc-1.png"
            alt="1"
          />
          <img
            :class="bem('material-model__img')"
            src="../assets/about-img-tpsc-2.png"
            alt="1"
          />
        </div>

        <p :class="bem('note')">更多模板请登录FocoSlide体验</p>
      </div>
    </div>
  </div>
</template>

<script>
import bemMixin from "../../../mixins/bem";
import { Tabs, TabPanel, Button, Icon } from "@gaoding/gdui";
// import IconDownload from "../../../components/icons/download.vue";
import Cover from "../../../components/icons/cover.vue";
import Directory from "../../../components/icons/directory.vue";
import Excessive from "../../../components/icons/excessive.vue";
import Graphic from "../../../components/icons/graphic.vue";
import BackCover from "../../../components/icons/backCover.vue";

export default {
  name: "about-page-left",
  mixins: [bemMixin],
  components: {
    // IconDownload
    Cover,
    Directory,
    Excessive,
    Graphic,
    BackCover
  },
  props: {
    rightShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tabsName: "cover"
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changetabs(value) {
      this.tabsName = value;
    }
  }
};
</script>

<style lang="less">
@import "../../../mixins/base.less";

@page-left: ~"about-page-left";

.@{page-left} {
  width: 60%;
  background: rgba(247, 249, 252, 1);

  &__model {
    margin-top: 15.125rem;
  }

  &__model-text {
  }

  &__model-title {
    font-size: 2.25rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    height: 3.5rem;
    line-height: 3.5rem;
  }

  &__model-sub-title {
    font-size: 1.5rem;
    height: 2.25rem;
    font-weight: 400;
    color: rgba(141, 148, 158, 1);
    line-height: 2.25rem;
    margin-top: 0.625rem;
  }

  &__highlight-blue {
    color: rgba(50, 115, 238, 1);
  }

  &__model-img {
    margin-top: 6.25rem;
  }

  .g-tabs__bar {
    justify-content: space-around;
    border: none;

    .g-btn {
      font-size: 1rem;
      width: 6rem;
      height: 2.875rem;
      font-weight: 600;
      color: #8d949e;
    }

    .g-tabs__tab--active {
      .g-btn {
        background: #3273ee;
        color: #ffffff;
      }
    }
  }
  .g-tabs__content {
    margin-top: 0;
  }
  .g-tabs__ink-bar {
    background: url(../../../assets/icon-selected.svg) no-repeat no-repeat;
    background-size: 100% 100%;
    bottom: 0.3125rem;
    height: 0.5rem;
  }

  &__category {
    width: 76.5%;
    margin: 2rem auto;
    display: flex;
    text-align: left;
    justify-content: center;
    img {
      width: 96.8%;
      height: auto;
      border-radius: 0.5rem;
      border: 1px solid rgba(221, 223, 229, 1);
    }
    display: flex;
    text-align: left;
    justify-content: center;
  }

  &__note {
    font-size: 1rem;
    height: 1.5rem;
    font-weight: 400;
    color: rgba(141, 148, 158, 1);
    line-height: 1.5rem;
    margin-top: 2rem;
  }

  &__material-model-warp {
    width: 76.5%;
    padding: 1.5rem 0 5rem 0;
    margin: 0 auto;
  }
  &__material-model {
    margin-top: 3.5rem;

    &__title-warp {
      text-align: left;
    }
    &__title {
      font-size: 1.25rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
    }
    &__sub-title {
      font-size: 0.875rem;
      font-weight: 400;
      color: rgba(190, 195, 201, 1);
    }

    &__img-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem;

      img {
        width: 90%;
        height: auto;
        border-radius: 0.5rem;
        border: 1px solid rgba(221, 223, 229, 1);
      }
    }

    &__img {
      width: 100%;
      height: auto;
      margin-top: 1.5rem;
      border-radius: 0.5rem;
      border: 1px solid rgba(221, 223, 229, 1);
    }
  }
  &__tabs {
    display: flex;
    flex-direction: column;
    margin-left: 4.375rem;
    border-left: 1px solid #e6e9eb;
    &-title {
      flex: 1;
      width: max-content;
      & > div {
        cursor: pointer;
        padding-left: 2.25rem;
        text-align: center;
        font-size: 1rem;
      }
      &-disaction {
        border-left: rgba(247, 249, 252, 1) 3px solid;
      }
      &-action {
        color: #3273ee;
        border-left: #3273ee 3px solid;
      }
      svg {
        vertical-align: bottom;
      }
    }
  }
  &__TabPanel {
    display: inline-block;
    img:last-child {
      margin-top: 1.25rem;
    }
  }
  &__tabs {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    border-left: 1px solid #e6e9eb;
    &-title {
      flex: 1;
      & > div {
        cursor: pointer;
        padding-left: 36px;
        text-align: center;
        font-size: 16px;
      }
      &-action {
        color: #3273ee;
        border-left: #3273ee 3px solid;
      }
      svg {
        vertical-align: bottom;
      }
    }
  }
  &__TabPanel {
    display: inline-block;
    img:last-child {
      margin-top: 20px;
    }
  }
}
.margin {
  margin-top: 6.25rem;
}
</style>
