<template>
  <div id="aboutPage">
    <Header></Header>
    <PageRight
      :rightShow="rightShow"
      :isScrollBottom="isScrollBottom"
      :rightPaddingBottom="rightPaddingBottom"
      :class="
        rightShow ? 'focoslide-page-right' : 'focoslide-page-right_bottom'
      "
    />
    <PageLeft :rightShow="rightShow" />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageLeft from "./components/page-left.vue";
import PageRight from "./components/page-right.vue";

export default {
  name: "about",
  props: {
    msg: String
  },
  components: {
    Header,
    Footer,
    PageLeft,
    PageRight
  },
  data() {
    return {
      rightShow: true,
      isScrollBottom: false,
      rightPaddingBottom: 0
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("resize", this.resizeHandler, false);
    window.addEventListener("scroll", this.onWindowScroll, false);
  },
  methods: {
    resizeHandler(event) {
      const innerWidth = event
        ? event.target.innerWidth
        : document.body.clientWidth;
      // 屏幕像素比切换
      if (innerWidth < window.screen.width * 0.6) {
        this.rightShow = false;
      } else {
        this.rightShow = true;
      }
    },
    onWindowScroll(e) {
      const windowScrollHeight =
        document.getElementById("aboutPage").clientHeight || 0;
      const footerHeight = document.getElementById("footer").clientHeight || 0;
      const innerScrollTop =
        windowScrollHeight - window.innerHeight - footerHeight;
      const currentScrollTop = e.target.scrollingElement.scrollTop;

      if (currentScrollTop > innerScrollTop) {
        this.isScrollBottom = true;
        this.rightPaddingBottom = (currentScrollTop - innerScrollTop) * 2;
      } else {
        this.isScrollBottom = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.focoslide-page-right {
  position: fixed;
  right: 0;
  top: 0;
  &_bottom {
    padding: 20px 0;
  }
}
</style>
