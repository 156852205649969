<!--  -->
<template>
  <div :class="bem()">
    <Header :logoColor="'white'"></Header>
    <div :class="bem('header')">
      <img
        :class="bem('header-bg')"
        src="./assets/header-bg.png"
        width="100%"
        height="auto"
      />
      <div :class="bem('header-title')">帮助中心</div>
    </div>
    <div :class="bem('body')">
      <div :class="bem('body-group')">
        <div :class="bem('body-module')">
          <div :class="bem('body-module_title')">
            <img
              :class="bem('body-module_title-icon')"
              src="./assets/question-common.svg"
            />
            <div :class="bem('body-module_title-text')">常见问题</div>
          </div>
          <ul :class="bem('body-module_body')">
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8350">
                FocoSlide是否收费？
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8354">
                FocoSlide需要联网吗？离线可用吗？
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8355">
                如何将多种登录方式（手机/微信/QQ）绑定成一种？
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8356">
                FocoSlide 支持哪些系统/Office版本，WPS能用吗？
              </a>
            </li>
          </ul>
        </div>
        <div :class="bem('body-module')">
          <div :class="bem('body-module_title')">
            <img
              :class="bem('body-module_title-icon')"
              src="./assets/question-login.svg"
            />
            <div :class="bem('body-module_title-text')">登录/付费相关</div>
          </div>
          <ul :class="bem('body-module_body')">
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8368">
                注册与登录FocoSlide账号
              </a>
            </li>
          </ul>
        </div>
        <div :class="bem('body-module')">
          <div :class="bem('body-module_title')">
            <img
              :class="bem('body-module_title-icon')"
              src="./assets/question-log.svg"
            />
            <div :class="bem('body-module_title-text')">使用与日志报错</div>
          </div>
          <ul :class="bem('body-module_body')">
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8371">
                安装后显示“实例方法的委托不能具有空”This“
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8372">
                设计工具不显示/点击设计工具功能无反应
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div :class="bem('body-group')">
        <div :class="bem('body-module')">
          <div :class="bem('body-module_title')">
            <img
              :class="bem('body-module_title-icon')"
              src="./assets/question-install.svg"
            />
            <div :class="bem('body-module_title-text')">安装与加载</div>
          </div>
          <ul :class="bem('body-module_body')">
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8357">
                如何安装FocoSlide？
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8358">
                如何卸载FocoSlide旧版本？
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8359">
                安装异常：“FocoSlide cannot be installed on systems without
                Visual Studio Tools for Office...
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8360">
                安装成功后，菜单栏不显示Focoslide
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8361">
                此windows
                installer安装包有一个问题。完成此安装的程序不能运行，请和你的支持人员或代理商联系。
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8362">
                COM加载项显示“已卸载”/COM加载项被禁用
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8365">
                安装时提示“Another instanc of setup is already running”
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8366">
                FocoSlide 无法安装在.NET Framework低于4.5的系统上
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.gaoding.com/article/8702">
                安装期间出错：无法安装此解决方案，因为您尚未选择信任为其签名的发布者。
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import bemMixin from "../../mixins/bem";
import Header from "../../components/header";
import Footer from "../../components/footer";

export default {
  name: "help_page",
  mixins: [bemMixin],
  components: {
    Header,
    Footer
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
  updated() {},
  destroyed() {},
  activated() {}
};
</script>
<style lang="less" scoped>
a {
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接，鼠标在元素上按下还没有松开*/
a:active {
  text-decoration: none;
}
/* 获得焦点的时候 鼠标松开时显示的颜色*/
a:focus {
  text-decoration: none;
}
.help_page {
  &__header {
    width: 100%;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    overflow: hidden;
    position: relative;

    &-bg {
      float: left;
    }

    &-title {
      float: left;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }
  &__body {
    width: 1160px;
    margin: 0 auto;
    margin-top: 56px;
    margin-bottom: 120px;
    display: flex;

    &-group {
      width: 526px;
      text-align: left;
    }
    .help_page__body-group:first-child {
      margin-right: 112px;
    }

    &-module {
      margin-bottom: 64px;
      &_title {
        display: flex;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        align-items: center;
        border-bottom: 1px solid rgba(34, 39, 51, 0.1);
        padding-bottom: 13px;
        margin-bottom: 16px;

        &-icon {
          width: 22px;
          height: 22px;
          margin-right: 14px;
        }
      }

      &_body {
        li {
          margin-top: 12px;
          cursor: pointer;
        }
        a {
          display: block;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        a:hover {
          color: rgba(50, 115, 238, 1);
        }
      }
    }
  }
}
</style>
