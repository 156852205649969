<!--  -->
<template>
  <svg
    t="1579057155328"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3561"
    :width="width + 'px'"
    :height="height + 'px'"
    :fill="colorValue"
  >
    <path
      d="M234.666667 853.333333v42.666667h-85.333334v-42.666667h85.333334z m128 0v42.666667h-85.333334v-42.666667h85.333334z m128 0v42.666667h-85.333334v-42.666667h85.333334z m128 0v42.666667h-85.333334v-42.666667h85.333334z m128 0v42.666667h-85.333334v-42.666667h85.333334z m128 0v42.666667h-85.333334v-42.666667h85.333334zM810.666667 128a42.666667 42.666667 0 0 1 42.666666 42.666667v640h-42.666666V170.666667H213.333333v640H170.666667V170.666667a42.666667 42.666667 0 0 1 42.666666-42.666667h597.333334z m-234.666667 341.333333v42.666667h-213.333333v-42.666667h213.333333z m85.333333-128v42.666667h-298.666666V341.333333h298.666666z"
      p-id="3562"
    ></path></svg
></template>

<script>
import color from "../../mixins/setColor";

export default {
  name: "download",
  mixins: [color]
};
</script>
